import { Scope } from './../models/scope';
import { Injectable } from '@angular/core';
import { User } from 'firebase/auth';

import { Claim } from '@app/core/models/claim';

const TOKEN_KEY = 'auth-token';
const REFRESH_KEY = 'auth-refresh';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private user: User = null;
  private refresh = '';
  private token = '';

  public get claims(): Claim {
    if (this.token !== null && this.token !== undefined && this.token !== '') {
      const decodedJWT = JSON.parse(window.atob(this.token.split('.')[1]));

      return {
        api_id: decodedJWT.api_id,
        user_id: decodedJWT.user_id,
        staff_scope_id: decodedJWT.staff_scope_id,
        staff_scope_type: decodedJWT.staff_scope_type,
        staff_scope_role: decodedJWT.staff_scope_role,
        director_scope_id: decodedJWT.director_scope_id,
        director_scope_type: decodedJWT.director_scope_type,
        director_scope_role: decodedJWT.director_scope_role,
        phone: decodedJWT.phone_number,
      } as Claim;
    }
    return null;
  }

  constructor() {
    this.readToken();
  }

  private readToken(): void {
    this.token = this.read(TOKEN_KEY);
    if (this.token && this.isExpired()) {
      this.destroy();
    }
  }

  private save(key: string, value: string, keep = false): void {
    if (keep) {
      localStorage.setItem(key, value);
    } else {
      window.sessionStorage.setItem(key, value);
    }
  }

  private saveRefresh(refresh: string): void {
    this.remove(REFRESH_KEY);
    this.save(REFRESH_KEY, refresh, true);
  }

  private saveToken(token: string): void {
    this.remove(TOKEN_KEY);
    this.save(TOKEN_KEY, token);
  }

  private read(key: string): string {
    const data = window.sessionStorage.getItem(key);
    if (data !== undefined && data !== null) {
      return data;
    }
    return localStorage.getItem(key);
  }

  private remove(key: string): void {
    window.sessionStorage.removeItem(key);
    localStorage.removeItem(key);
  }

  public getApiId(): string {
    return this.claims.api_id;
  }

  public getRefresh(): string | null {
    return this.token;
  }

  public getToken(): string | null {
    return this.token;
  }

  public getUser(): any {
    return this.user;
  }

  public isExpired(): boolean {
    const expiry = JSON.parse(atob(this.token.split('.')[1])).exp;
    const expired: boolean = Math.floor(new Date().getTime() / 1000) >= expiry;

    if (this.token === undefined || this.token === null || expired) {
      return true;
    } else {
      return false;
    }
  }

  public isLoggedIn(): boolean {
    return this.token !== undefined && this.token !== null;
  }

  public async processCredential(credential: User): Promise<void> {
    console.log('Process Credential: ', credential);

    // USER
    this.user = credential;

    // REFRESH TOKEN
    this.refresh = credential.refreshToken;
    this.saveRefresh(this.refresh);

    // TOKEN
    this.token = await credential.getIdToken();
    console.log('Token: ', this.token);
    this.saveToken(this.token);
  }

  public destroy(): void {
    window.sessionStorage.clear();
    localStorage.clear();
    this.user = null;
    this.refresh = null;
    this.token = null;
  }
}
