import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { AuthService } from '@app/core/services/auth.service';
import { LoadingComponent } from '@app/layout/loading/loading.component';
import { NotificationComponent } from '@app/layout/notification/notification.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  standalone: true,
  imports: [RouterOutlet, LoadingComponent, NotificationComponent],
})
export class AppComponent {
  public readonly authService = inject(AuthService);
}
