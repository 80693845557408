import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { AppCheckTokenResult } from '@firebase/app-check';
import { Observable, from } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { AppCheckService } from '@app/core/services/app-check.service';
import { environment } from '@app/environments/environment';

export function appCheckInterceptor(
  request: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> {
  console.log('RequestURL: ', request.url);
  // console.log("Env RequestURL: ", environment.eventsApiEndpoint);
  if (!request.url.includes(environment.eventsApiEndpoint)) {
    const appCheckService = inject(AppCheckService);
    return from(appCheckService.getToken()).pipe(
      take(1), // See https://stackoverflow.com/a/60196923/828547.
      switchMap((token: AppCheckTokenResult | undefined) => {
        if (token) {
          request = request.clone({
            setHeaders: { 'X-Firebase-AppCheck': token?.token },
          });
        }
        return next(request);
      })
    );
  } else {
    return next(request);
  }
}
