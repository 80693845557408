import { Injectable, NgZone, inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import * as auth from 'firebase/auth';
import firebase from 'firebase/compat/app';

import { INotification } from '@app/core/models/notification';
import { User } from '@app/core/models/user';
import { EventsApiService } from '@app/core/services/events-api.service';
import { LoadingService } from '@app/core/services/loading.service';
import { NotificationService } from '@app/core/services/notification.service';
import { TokenService } from '@app/core/services/token.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private canAccess = false;

  private readonly eventApi = inject(EventsApiService);
  private readonly loadingSvc = inject(LoadingService);
  private readonly notify = inject(NotificationService);
  private readonly router = inject(Router);
  private readonly token = inject(TokenService);

  public readonly afs = inject(AngularFirestore); // Inject Firestore service
  public readonly afAuth = inject(AngularFireAuth); // Inject Firebase auth service
  public readonly ngZone = inject(NgZone); // NgZone service to remove outside scope warning

  /* Saving user data in localstorage when
    logged in and setting up null when logged out */
  private readonly authState$ = this.afAuth.authState.subscribe((user) =>
    this.handleAuthState(user)
  );

  public userData: unknown; // Save logged in user data
  public reCaptchaVerifier: auth.ApplicationVerifier;
  public verificationId: string;

  // Returns true when user is looged in and email is verified
  public get isLoggedIn(): boolean {
    const claims = this.token.claims;
    return (
      claims !== null &&
      claims !== undefined &&
      claims.phone !== null &&
      claims.phone !== undefined &&
      this.canAccess === true
    );
  }

  private async checkCanAccess(): Promise<void> {
    // console.log('Claims: ', this.token.claims);
    if (this.token.claims !== null && this.token.claims !== undefined) {
      console.log('Claim Staff Role:', this.token.claims.staff_scope_role);
      switch (this.token.claims.staff_scope_role) {
        case 'MASTER':
        case 'SUPPORT':
        case 'TRANSIT':
        case 'UNION_LEADER':
        case 'UNION_SECRETARY':
        case 'CONFERENCE_LEADER':
        case 'CONFERENCE_SECRETARY':
        case 'COORDINATOR':
        case 'REGIONAL':
          console.log('CanAccess: TRUE');
          this.canAccess = true;
          return;
      }

      console.log('CanAccess: FALSE');
      this.canAccess = false;
      await this.signOut();
      this.notify.error({
        title: 'Acesso negado!',
        text: 'Você não tem permissão para acessar este portal. Ele é exclusivo para a coordenação!',
        position: 'center center',
      } as INotification);
    }
  }

  private async handleAuthState(user: firebase.User): Promise<void> {
    if (user) {
      await this.token.processCredential(user);
      this.userData = this.token.getUser();
      await this.checkCanAccess();
      console.log('home');
      this.router.navigateByUrl('/home');
    } else {
      this.token.destroy();
    }
  }

  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  private setUserData(user: any): Promise<void> {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${user.uid}`
    );
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
    };
    return userRef.set(userData, {
      merge: true,
    });
  }

  // Sign in with phone number
  public async requestPhoneSMS(phoneNumber: string): Promise<void> {
    this.loadingSvc.startLoading();
    console.log('requestPhoneSMS: ', phoneNumber);
    if (this.reCaptchaVerifier == null) {
      this.startRecaptcha();
    }

    firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, this.reCaptchaVerifier)
      .then((result) => {
        console.log('Success: ', result);
        this.verificationId = result.verificationId;
        // this.reCaptchaVerifier = null;
        this.loadingSvc.stopLoading();
      })
      .catch((error) => {
        this.loadingSvc.stopLoading();
        console.log('Error: ', error);
      });
  }

  public startRecaptcha(): void {
    this.reCaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha', {
      size: 'invisible',
    });
  }

  // Validate SMS code received
  public async validateSmsCode(otpCode: string): Promise<void> {
    this.loadingSvc.startLoading();
    const credentials = firebase.auth.PhoneAuthProvider.credential(
      this.verificationId,
      otpCode
    );

    try {
      const result = await firebase.auth().signInWithCredential(credentials);
      await this.token.processCredential(result.user);
      // await this.canAccess();
      await this.checkCanAccess();

      this.notify.success({
        toast: true,
        title: 'Login efetuado com sucesso!',
        icon: 'success',
      } as INotification);
      this.loadingSvc.stopLoading();
    } catch (error) {
      this.loadingSvc.stopLoading();
      this.notify.error({
        toast: true,
        title: 'Acesso negado!',
        text: 'Erro ao validar o código SMS!',
      } as INotification);
      console.log('validateSmsCode Try ER8ROR: ', error);
    }
  }

  // private async canAccess(): Promise<boolean> {
  //   let apiId = this.token.getApiId();

  //   if(apiId === null){
  //     await this.signOut();
  //     this.notify.error({
  //       toast: true,
  //       title: 'Código API ID inválido!',
  //     } as INotification);
  //   }
  //   let result = await this.eventApi.getCanAccess(apiId);
  //   console.log("Result CanAccess", result);

  //   if(result.canAccess) {
  //     console.log("Result CanAccess TRUE", result.canAccess);
  //     this.canAccess = true;
  //     return true;
  //   }

  //   console.log("Result CanAccess FALSE", result.canAccess);
  //   this.canAccess = false;

  //   await this.signOut();
  //   this.notify.error({
  //     title: 'Acesso negado!',
  //     text: 'Você não tem permissão para acessar este portal. Ele é exclusivo para a coordenação!'
  //   } as INotification);
  //   return false;
  // }

  public reloadPage(): void {
    this.navigateToRoot();
    // window.location.reload();
  }

  public navigateToRoot(): void {
    console.log('Router', this.router);
    this.router.navigateByUrl('/');
    console.log('NavigateToRoot');
  }

  public requestUpdatedToken(): void {
    const refreshToken = this.token.getRefresh();
    // eslint-disable-next-line no-empty
    if (refreshToken !== null && refreshToken !== undefined) {
    }
  }

  // Auth logic to run auth providers
  public async authLogin(provider: firebase.auth.AuthProvider): Promise<void> {
    try {
      const { user } = await this.afAuth.signInWithPopup(provider);
      this.setUserData(user);
      this.reloadPage();
    } catch (error) {
      console.log(error);
    }
  }

  // Reset Forggot password
  public async forgotPassword(passwordResetEmail: string): Promise<void> {
    try {
      await this.afAuth.sendPasswordResetEmail(passwordResetEmail);
      window.alert('Password reset email sent, check your inbox.');
    } catch (error) {
      window.alert(error);
    }
  }

  // Sign in with Google
  public async googleAuth(): Promise<void> {
    try {
      await this.authLogin(new auth.GoogleAuthProvider());
      this.navigateToRoot();
    } catch (error) {
      console.log(error);
    }
  }

  // Send email verfificaiton when new user sign up
  public async sendVerificationMail(): Promise<void> {
    try {
      const user = await this.afAuth.currentUser;
      await user.sendEmailVerification();
      this.router.navigate(['verify-email-address']);
    } catch (error) {
      // TODO: create generic error message service
      window.alert(error.message);
    }
  }

  // Sign in with email/password
  public async signInEmail(inputData: {
    email: string;
    password: string;
  }): Promise<void> {
    const result = await this.afAuth.signInWithEmailAndPassword(
      inputData.email,
      inputData.password
    );
    this.setUserData(result.user);
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.reloadPage();
      }
    });
  }

  // Sign up with email/password
  public async signUp(email: string, password: string): Promise<void> {
    try {
      const result = await this.afAuth.createUserWithEmailAndPassword(
        email,
        password
      );
      /* Call the SendVerificaitonMail() function when new user sign
       * up and returns promise
       */
      this.sendVerificationMail();
      this.setUserData(result.user);
    } catch (error) {
      window.alert(error.message);
    }
  }

  // Sign out
  public async signOut(): Promise<void> {
    console.log('signOut start');
    await this.afAuth.signOut();
    this.token.destroy();
    //this.notify.success({ toast: true, title: 'Usuário desconectado' } as INotification);
    console.log('Router', this.router);
    console.log('IsLoggedIn: ', this.isLoggedIn);
    setTimeout(() => {
      this.router.navigateByUrl('/login');
    }, 1);

    // this.reloadPage();
    console.log('signOut end');
  }
}
