export interface INotification {
  toast: boolean;
  width: string;
  position: string;
  icon: string;
  title: string;
  text: string;
  confirmButtonText: string;
  showConfirmationButton: boolean;
  timer: number;
  timerProgressBar: boolean;
  type: NotificationType;
  reloadWhenExit: boolean;
  runWhenExit: any;
}

export enum NotificationType {
  Error = 0,
  Success = 1,
  Warning = 2,
}
