import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { ICanAccess } from '@app/core/models/can-access';
import { PaginationResult } from '@app/core/models/pagination-return';
import { environment } from '@app/environments/environment';

const API_URL = environment.eventsApiEndpoint;

@Injectable({
  providedIn: 'root',
})
export class EventsApiService<T> {
  private readonly http = inject(HttpClient);

  public async getCanAccess(userid: string): Promise<ICanAccess> {
    const source$ = this.http.get(
      API_URL + '/v2/users/' + userid + '/canAccess',
      { responseType: 'text' }
    );
    return JSON.parse(await firstValueFrom<any>(source$));
  }

  public async getUsersToReview(status: string): Promise<PaginationResult<T>> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('status', status);

    const source$ = this.http.get(API_URL + '/v2/reviewusers', {
      params: queryParams,
      responseType: 'json',
    });
    const value = await firstValueFrom<any>(source$);
    console.log('Value: ', value);
    return value;
  }

  public async postApproveUsers(appId: string): Promise<any> {
    const source$ = this.http.post(
      API_URL + '/v2/reviewusers/' + appId + '/approve',
      { responseType: 'json' }
    );
    const result = await firstValueFrom<any>(source$);
    console.log('postApproveUsers', result);
    return result;
  }

  public async postRejectUsers(appId: string): Promise<any> {
    const source$ = this.http.post(
      API_URL + '/v2/reviewusers/' + appId + '/reject',
      { responseType: 'text' }
    );
    const result = await firstValueFrom<any>(source$);
    console.log('postRejectUsers', result);
    return result;
  }
}
