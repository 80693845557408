import { Routes } from '@angular/router';

import { authGuard } from '@app/core/guards/auth.guard';

export const appRoutes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () =>
      import('@app/modules/auth/auth.routes').then((m) => m.routes),
  },
  {
    path: '',
    canActivateChild: [authGuard],
    loadComponent: () =>
      import('@app/layout/layout.component').then((m) => m.LayoutComponent),
    children: [
      {
        path: 'home',
        loadChildren: () =>
          import('@app/modules/home/home.routes').then((m) => m.routes),
      },
      {
        path: 'review',
        loadChildren: () =>
          import('@app/modules/members/directors/directors.routes').then(
            (m) => m.routes
          ),
      },
    ],
  },
  {
    path: '**',
    loadComponent: () =>
      import('@app/layout/page-not-found/page-not-found.component').then(
        (m) => m.PageNotFoundComponent
      ),
  },
];
