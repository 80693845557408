import { Injectable } from '@angular/core';
import { getAnalytics } from 'firebase/analytics';
import { getApp } from 'firebase/app';
import {
  AppCheckTokenResult,
  ReCaptchaV3Provider,
  getToken,
  initializeAppCheck,
} from 'firebase/app-check';

import { environment } from '@app/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppCheckService {
  private readonly app = getApp();

  private readonly appCheck = initializeAppCheck(this.app, {
    // provider: new ReCaptchaEnterpriseProvider(environment.firebase.recaptchaEnterpriseKey),
    provider: new ReCaptchaV3Provider(environment.firebase.recaptchaV3Key),
    isTokenAutoRefreshEnabled: false, // Set to true to allow auto-refresh.
  });

  private readonly analytics = getAnalytics(this.app);

  public async getToken(): Promise<AppCheckTokenResult | undefined> {
    let appCheckTokenResponse;
    try {
      appCheckTokenResponse = await getToken(this.appCheck);
    } catch (err) {
      // TODO: Handle any errors if the token was not retrieved.
      console.log('AppCheck Service GetToken Error', err);
      return undefined;
    }
    console.log('AppCheck Service GetToken Success');
    return appCheckTokenResponse;
  }
}
