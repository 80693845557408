import { NgClass } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { LoadingService } from '@app/core/services/loading.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css'],
  standalone: true,
  imports: [MatProgressSpinnerModule, NgClass],
})
export class LoadingComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  private readonly loadingSvc = inject(LoadingService);

  public showLoading = false;

  public ngOnInit(): void {
    //this.loadingSvc.startLoading();

    this.loadingSvc.isLoading$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isLoading) => {
        this.render(isLoading);
      });

    // this.loadingSvc.stopLoading();
  }

  private render(isLoading: boolean): void {
    console.log('Render Loading: ', isLoading);
    if (isLoading !== null && isLoading !== undefined) {
      this.showLoading = isLoading;
    }
  }
}
