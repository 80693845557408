import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { TokenService } from '@app/core/services/token.service';

const TOKEN_HEADER_KEY = 'Authorization';

export function authInterceptor(
  request: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> {
  let authReq = request;

  const skipJson = request.headers.get('skipJson');
  let headers = null;

  const tokenSrv = inject(TokenService);
  const token = tokenSrv.getToken();
  if (token != null) {
    if (skipJson === 'true') {
      headers = request.headers
        .append(TOKEN_HEADER_KEY, 'Bearer ' + token)
        .append('Access-Control-Allow-Methods', 'GET, POST, PUT, DELETE')
        .append('Access-Control-Allow-Origin', '*');
    } else {
      headers = request.headers
        .append(TOKEN_HEADER_KEY, 'Bearer ' + token)
        .append('content-type', 'application/json')
        .append('Access-Control-Allow-Headers', 'Content-Type')
        .append('Access-Control-Allow-Methods', 'GET, POST, PUT, DELETE')
        .append('Access-Control-Allow-Origin', '*');
    }

    authReq = request.clone({ headers: headers });
  }

  return next(authReq).pipe(
    catchError((err) => {
      if (err.status === 401) {
        console.log('Erro 401', err);
        // auto logout if 401 response returned from api
        tokenSrv.destroy();
        window.location.reload();
      }

      const error = err.error.errors || err;
      throw new Error(error);
    })
  );
}
