import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { filter } from 'rxjs';
import Swal from 'sweetalert2';

import { INotification, NotificationType } from '@app/core/models/notification';
import { NotificationService } from '@app/core/services/notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css'],
  standalone: true,
  imports: [],
})
export class NotificationComponent implements OnInit {
  private readonly classMap = new Map<NotificationType, string>();
  private readonly destroyRef = inject(DestroyRef);
  private readonly notificationService = inject(NotificationService);
  private readonly router = inject(Router);

  public ngOnInit(): void {
    this.classMap.set(NotificationType.Success, 'success');
    this.classMap.set(NotificationType.Warning, 'warning');
    this.classMap.set(NotificationType.Error, 'error');

    this.notificationService.notification$
      .pipe(filter(Boolean), takeUntilDestroyed(this.destroyRef))
      .subscribe((notification) => {
        this.render(notification);
      });
  }

  private render(n: INotification): void {
    switch (n.type) {
      case NotificationType.Success:
        Swal.fire({
          toast: n.toast ?? false,
          width: n.width ?? '20rem',
          position: 'top-end',
          icon: 'success',
          title: n.title,
          text: n.text,
          confirmButtonText: n.confirmButtonText ?? 'ok',
          showConfirmButton: n.showConfirmationButton ?? false,
          timer: n.timer ?? 2000,
          timerProgressBar: n.timerProgressBar ?? true,
        }).then(() => {
          if (n.reloadWhenExit) {
            this.router.navigateByUrl('/');
            // window.location.reload();
          }
        });
        break;

      case NotificationType.Error:
        Swal.fire({
          toast: n.toast ?? false,
          width: n.width ?? '20rem',
          position: 'top-end',
          icon: 'error',
          title: n.title,
          text: n.text,
          confirmButtonText: n.confirmButtonText ?? 'ok',
          showConfirmButton: n.showConfirmationButton ?? false,
          timer: n.timer ?? 2000,
          timerProgressBar: n.timerProgressBar ?? true,
        }).then(() => {
          if (n.reloadWhenExit) {
            this.router.navigateByUrl('/');
            // window.location.reload();
          }
        });
        break;

      case NotificationType.Warning:
        Swal.fire({
          toast: n.toast ?? false,
          width: n.width ?? '20rem',
          position: 'top-end',
          icon: 'warning',
          title: n.title,
          text: n.text,
          confirmButtonText: n.confirmButtonText ?? 'ok',
          showConfirmButton: n.showConfirmationButton ?? false,
          timer: n.timer ?? 2000,
          timerProgressBar: n.timerProgressBar ?? true,
        }).then(() => {
          if (n.reloadWhenExit) {
            this.router.navigateByUrl('/');
            // window.location.reload();
          }
        });
        break;
    }
  }
}
