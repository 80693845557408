import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  provideRouter,
  withEnabledBlockingInitialNavigation,
} from '@angular/router';

import { appRoutes } from '@app/app.routes';
import { appCheckInterceptor } from '@app/core/helpers/app-check.interceptor';
import { authInterceptor } from '@app/core/helpers/auth.interceptor';
import { provideFirebaseConfigurations } from '@app/core/providers/firebase.provider';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideRouter(appRoutes, withEnabledBlockingInitialNavigation()),
    provideHttpClient(withInterceptors([authInterceptor, appCheckInterceptor])),
    provideFirebaseConfigurations(),
  ],
};
