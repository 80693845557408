import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { INotification, NotificationType } from '@app/core/models/notification';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  public readonly notification$ = new BehaviorSubject<INotification>(null);

  private notify(n: INotification): void {
    this.notification$.next(n as INotification);
  }

  public error(n: INotification): void {
    n.type = NotificationType.Error;
    this.notify(n);
  }

  public success(n: INotification): void {
    n.type = NotificationType.Success;
    this.notify(n);
  }

  public warning(n: INotification): void {
    n.type = NotificationType.Warning;
    this.notify(n);
  }
}
