/**
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  eventsApiEndpoint: "https://events-api-dev.camporionline.org",
  firebase: {
    projectId: "campori-online-dev",
    appId: "1:159117188852:web:62a07985ba806d415a48a1",
    storageBucket: "campori-online-dev.appspot.com",
    // databaseURL: 'https://campori-online-default-rtdb.firebaseio.com',
    locationId: 'southamerica-east1',
    apiKey: "AIzaSyCh6sq9Q-LVn_XwC-AjfY_2G8c1TgEYMq8",
    authDomain: "campori-online-dev.firebaseapp.com",
    messagingSenderId: "159117188852",
    measurementId: "G-91P1V34XZV",
    recaptchaEnterpriseKey: "6Lerz04mAAAAAPUcXrY4F2si589coYlQvt-ESm1f",
    recaptchaV3Key: "6Lerz04mAAAAAPUcXrY4F2si589coYlQvt-ESm1f",
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
