import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { AuthService } from '@app/core/services/auth.service';

export const authGuard: CanActivateFn = (): boolean => {
  if (inject(AuthService).isLoggedIn !== true) {
    inject(Router).navigate(['login']);
    console.log('AuthGuard Blocked');
    return false;
  }
  console.log('AuthGuard Granted');
  return true;
};
